/* You can add global styles to this file, and also import other style files */
@import 'bootstrap';
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

body {
  box-sizing: border-box;
  font-family:"Poppins","Roboto", sans-serif;
  margin: 0; 
}

.pointer {
  cursor: pointer;
}

.section-spacing {
  margin-bottom: 40px;
}

.h-14{
  min-height: 14vh;
}


.icon-img-size {
  height: 50px;
  width: auto;
  max-width: 100%;
}

.blue-backdrop {
  background-color: rgba(1, 11, 17, 1);
}

.modal-dialog {
  background-color: rgba(13, 110, 253, 0.7);
}

.modal-content {
    background-color: transparent;
}

// .modal-backdrop {
//   background-color: rgba(0,0,0,.0001) !important;
// }

select, input, textarea:focus {
  box-shadow: none !important;
}